.whatsapp-button {
    position: fixed;
    bottom: 45px;
    right: 20px;
    z-index: 1000;
    background-color: #25D366;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatsapp-button img {
    width: 30px;
    height: 30px;
}

:root {
  --main-green: #2db875;
  --logo-green: #209a5f;
  --light-green: #e3ffec;
  --green-grey: #e3ebe9;
  --green: #c9fdda;
  --white: #ffffff;
  --main-black: #1f2320;
  --light-grey: #c5c7c3;
  --grey: #f5f8f6;
  --text-grey: #4a4f4b;
  font-family: "Inter";
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("assets/fonts/Inter-Bold.otf") format("otf");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("assets/fonts/Inter-Medium.otf") format("otf");
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url("assets/fonts/Inter-Light.otf") format("otf");
}

#root {
  overflow-x: hidden;
}

@media (max-width: 991.98px) {
  #root {
    overflow-x: unset;
  }
}

.fw-500 {
  font-weight: 500;
}

.fs-18 {
  font-size: 18px;
}

.small_logo_txt {
  width: 20px;
  vertical-align: middle;
  margin-right: 8px;
}

.advertisement {
  background-color: #f5fcc7;
}

.advertisement__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}

@media (max-width: 991.98px) {
  .advertisement__container {
    padding: 10px 20px;
    height: unset;
  }

  .advertisement p {
    padding-right: 20px;
  }
}

.alert {
  height: 48px;
  border-radius: 0;
  background-color: var(--light-green);
  padding: 0;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  width: 100vw;
  z-index: 100;
  bottom: 0;
  margin-bottom: 0;
}

.alert__text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.alert__text p {
  font-weight: 500;
  margin-bottom: 0;
}

.alert__text img {
  width: 11px;
}

.navbar-toggler {
  border: none;
}

.container-left {
  --bs-gutter-x: 1.5rem;
  width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 2);
  margin-left: auto;
}

.mb-8 {
  margin-bottom: 96px;
}

.btn-black {
  background-color: var(--white);
  border: 1px solid var(--main-black);
  border-radius: 8px;
}

.btn-black:hover {
  background-color: var(--main-black);
  color: var(--white);
}

.btn {
  height: 36px;
  padding-right: 32px;
  padding-left: 32px;
}

.btn-empty {
  color: var(--main-black);
  font-weight: 500;
}

.btn-empty:hover {
  color: var(--logo-green);
  background: #f5f8f6;
  border-radius: 10px;
}

.btn-img {
  background-color: unset;
  border: none;
}

.round-icon {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 1px solid var(--main-black);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .round-icon {
    width: 40px;
    height: 40px;
  }

  .round-icon img {
    width: 14px;
  }
}

.grey_background_title {
  background-color: var(--green-grey);
  padding: 8px 16px;
  width: fit-content;
  border-radius: 10px;
}

.navigation-mobile {
  background-color: var(--white);
  height: 56px;
  padding-left: 5px;
  padding-right: 5px;
}

.navigation-mobile .navigation__bar {
  padding-left: 0;
  width: 100%;
}

.navigation-mobile__bar ul {
  list-style: none;
  height: 100vh;
  z-index: 200;
  background-color: var(--grey);
  width: 100%;
  padding: 0 16px;
  left: 0;
}

.navigation-mobile__bar a {
  font-weight: 400;
}

.navigation {
  background-color: var(--white);
  height: 72px;
  align-content: center;
  align-items: center;
}

.navigation__bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin-bottom: 0;
}

.navigation__bar li {
  margin-right: 40px;
  white-space: nowrap;
}

.navigation__bar a:link {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .navigation__bar li {
    margin-right: unset;
  }

  .menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 300;
  }
}

.main {
  position: relative;
  background-color: var(--main-green);
  height: 80vh;
}

.main .arrow {
  position: absolute;
  left: 40%;
  bottom: -43px;
}

.main__left {
  flex-direction: column;
  height: calc(100vh - 72px);
  justify-content: center;
  display: flex;
}

.main__left h1 {
  color: var(--white);
  text-align: start;
  margin-bottom: 90px;
  font-size: 48px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .main {
    height: 100vh;
  }

  .main__left {
    height: unset;
    margin-top: 56px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .main__left h1 {
    text-align: center;
    margin-bottom: 24px;
  }

  .main .download__icon {
    margin: auto;
  }

  .main .arrow {
    left: 65%;
    bottom: -170px;
  }
}

@media (max-width: 767.98px) {
  .main__left h1 {
    font-size: 36px;
  }
}

.download {
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
}

.download__qr img {
  width: 104px;
  border-radius: 8px;
  border: 1px solid black;
}

.download__icon {
  text-align: start;
  display: grid;
  gap: 8px;
}

.download__icon__txt {
  background-color: var(--white);
  padding: 14px;
  border: 1px solid var(--main-black);
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 0;
}

.main__right {
  display: flex;
  align-items: center;
}

.main__right__background {
  width: 30vw;
  max-width: 488px;
  height: 30vw;
  max-height: 488px;
  border-radius: 32px;
  border-right: 8px solid var(--main-black);
  border-bottom: 8px solid var(--main-black);
  padding-top: 40px;
  background: var(--white) url("assets/images/icons/logo-leaf-grey_icon.svg")
    space;
  position: relative;
}

.main__right__mobile {
  width: 66%;
}

.main__right__background__doc {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 72px;
  height: 72px;
}

.main__right__background__alert {
  position: absolute;
  bottom: -40px;
  left: -40px;
}

@media (max-width: 991.98px) {
  .main__right__background {
    padding-top: 24px;
    width: 80vw;
    height: 80vw;
    margin: auto;
  }

  .main__right__mobile {
    width: 75%;
  }

  .main__right__background__doc {
    top: -20px;
    right: -20px;
  }

  .main__right__background__alert {
    bottom: -20px;
    left: -20px;
  }

  .main__right {
    margin-top: unset;
    margin-bottom: 155px;
  }

  .main__row {
    height: 100vh;
  }
}

.about {
  margin-top: 240px;
  margin-bottom: 192px;
  display: flex;
  align-items: center;
}

.about__body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 150px;
}

.about__body h1 {
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .about__body {
    padding: 0 20px;
  }
}

.services {
  background-color: var(--light-green);
}

.services__body {
  height: fit-content;
  display: flex;
  gap: 20px;
  padding: 88px 20px 104px;
  justify-content: space-between;
}

.services__body__box {
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  box-shadow: 7px 7px 0px 1px var(--green);
  border-radius: 32px;
  flex-basis: 350px;
  flex-grow: 1;
  padding: 24px 48px;
}

.services__body__box h3 {
  margin-bottom: 12px;
  font-size: 22px;
}

.services__body__box__icon {
  width: 72px;
  height: 72px;
  border-radius: 72px;
  border: 1px solid var(--light-grey);
  margin: auto;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.functionality {
  margin-top: 168px;
  margin-bottom: 168px;
  position: relative;
}

.functionality__tools {
  display: flex;
  flex-direction: column;
}

.functionality__title {
  font-weight: 700;
  text-align: start;
  font-size: 36px;
}

.functionality__box:not(:last-child) {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--light-grey);
}

.functionality__box {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.functionality__box__icon {
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid #c5c7c3;
  width: 32px;
  height: 32px;
  padding: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.functionality__box__icon img {
  width: 17px;
  height: 17px;
}

.functionality__box__icon-inverse {
  background-color: var(--main-green);
  border: none;
}

.functionality__box__icon-inverse img {
  filter: brightness(0) invert(1);
}

.functionality__box__name {
  text-align: start;
}

.functionality__box__name__title {
  margin-bottom: 4px;
  cursor: pointer;
}

.functionality__screens {
  height: 568px;
  border: 1px solid var(--light-grey);
  border-radius: 32px;
  background-color: #f5fff4;
  background-image: url("assets/images/icons/background-logo-part.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  box-shadow: 9px 9px 0px 1px var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.functionality__screens__img {
  width: 47%;
}

.functionality .arrow {
  position: absolute;
  left: -60px;
  top: 80px;
}

.functionality__box-open {
  animation: growDown 300ms ease-in-out forwards;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@media (max-width: 991.98px) {
  .functionality__title {
    text-align: center;
  }

  .functionality__question {
    margin: auto;
    margin-bottom: 16px;
  }

  .functionality .arrow {
    transform: rotate(90deg);
    position: absolute;
    right: 15%;
    top: 110px;
    left: unset;
  }
}

.price {
  background-color: var(--main-green);
  margin-bottom: 40px;
}

.price .farmer img {
  object-fit: cover;
  height: 100%;
  width: 50vw;
}

.price .farmer {
  position: relative;
}

.price .farmer::before {
  content: "";
  background: #d4ffe2;
  opacity: 40%;
  width: 50vw;
  height: 100%;
  position: absolute;
}

.price__left {
  text-align: start;
  margin-top: 48px;
  margin-bottom: 78px;
  padding-right: 20px;
}

.price__left__txt {
  margin-right: 14px;
  color: var(--white);
}

.price__left__price {
  background-color: #e0fff0;
  border-radius: 8px;
  padding: 8px 16px;
}

.price__left__title {
  color: var(--white);
  font-weight: 700;
  font-size: 36px;
}

.price .arrow_container {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.price .arrow {
  transform: rotate(90deg);
  margin-top: 12px;
}

@media (max-width: 991.98px) {
  .price .container {
    padding: 0;
  }
  .price .download__icon {
    margin: auto;
    margin-bottom: 24px;
  }

  .price__left {
    padding-right: unset;
    text-align: center;
    margin-bottom: 48px;
  }

  .price__left__title {
    margin: 0 22px 24px 22px;
    font-size: 22px;
  }

  .price .farmer img {
    width: 100vw;
  }

  .price .farmer::before {
    width: 100vw;
  }

  .price .arrow {
    position: absolute;
    left: 70%;
  }
}

footer {
  background-color: var(--grey);
}

.footer {
  padding-top: 18px;
  padding-bottom: 18px;
}

.footer ul {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}

.footer li {
  margin-right: 40px;
  white-space: nowrap;
}

.footer a {
  font-weight: 400;
}

.footer a {
  text-decoration: none;
}

.footer p {
  color: var(--main-black);
  font-size: 14px;
}

@media (max-width: 991.98px) {
  .arrow {
    height: 65px;
    transform: rotate(78deg);
  }
}
.ordered-list ol{
  counter-reset: item;
}
.ordered-list > ol{
  padding-left:0;
}
.ordered-list li {
  display: inherit;
}
.ordered-list h5{
  display: inline-block;
  font-size: 1rem;
}
.ordered-list li:before{
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-weight: bold;
}
.ordered-list table{
  width: 100%;
  border: 1px solid;
}
.ordered-list table tbody{
  padding: 20px;
  display: block;
}
.ordered-list table tr td{
  padding: 5px;
}